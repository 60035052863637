/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import {
  camelCase,
  isEmpty,
  isEqual,
  kebabCase,
  startCase,
  identity,
} from 'lodash';

import {
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  CheckCircle as CheckedIcon,
  Delete as DeleteIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';

import pluralize from 'lib-frontend-shared/src/helpers/pluralize';

import Card from 'lib-frontend-shared/src/components/Card';
import CircularProgress from 'lib-frontend-shared/src/components/CircularProgress';
import Collapse from 'lib-frontend-shared/src/components/Collapse';
import FormButton from 'lib-frontend-shared/src/components/FormButton';
import Linear from 'lib-frontend-shared/src/components/Linear';
import Spacer from 'lib-frontend-shared/src/components/Spacer';
import Radio from 'lib-frontend-shared/src/components/Radio';
import Typography from 'lib-frontend-shared/src/components/Typography';

import sentenceCase from 'lib-frontend-shared/src/helpers/sentenceCase';
import useAsyncEffect from 'lib-frontend-shared/src/helpers/useAsyncEffect';
import useAsyncState from '../../helpers/useAsyncState';

import config from '../../config';

import {
  dismissAsyncTask,
  updateAsyncTasks,
} from '../../actions/async-task';

import Link from '../Link';
import { makeProgressDialog } from '../ProgressDialog';
import Table from '../Table';
import Tooltip from '../Tooltip';
import Menu from '../Menu';
import TableAddButton from '../TableAddButton';
import { makeUnifiedInput } from '../UnifiedInput';

export const generalSettingsTitle = 'Setup Settings';

const { backendBaseUrl } = config;

const UnifiedInput = makeUnifiedInput({
  mapping: {
    clearable: true,
    type: 'select',
    useNewAutocomplete: true,
    multiple: false,
    showValue: false,
  },
  textKey: {
    required: true,
    style: { width: '100%' },
    type: 'text',
  },
});

export const WebhookInfo = ({
  connectorWebhookUrlPrefix,
  event,
  eventLabel,
  index,
  optional,
  route = event,

  connectorId,
  tenantId,
  webhookApiVersion,

  contentGap = 'xs',
  contentVariant = 'para.sm',
  headingVariant = 'para.sm',
}) => {
  const entries = [
    { label: 'Event:', value: eventLabel || sentenceCase(event) },
    { label: 'Format:', value: 'JSON' },
    { label: 'URL:', value: `${backendBaseUrl}/${connectorWebhookUrlPrefix}/${route}?tenantId=${tenantId}&connectorId=${connectorId}` },
    { label: 'Webhook API version:', value: webhookApiVersion },
  ];
  return (
    <Linear gap={contentGap} key={event} orientation="vertical" width="100pr">
      <Typography variant={headingVariant}>
        {`Webhook ${index + 1}:${optional ? ' (Optional)' : ''}`}
      </Typography>
      <div />
      {entries.map(({ label, value }) => (
        <Linear align="start" gap="sm" key={`${event}-${label}`} widht="100pr">
          <Typography nowrap variant={`${contentVariant}:body`}>
            {label}
          </Typography>
          <Typography variant={contentVariant} style={{ wordBreak: 'break-all' }}>
            {value}
          </Typography>
        </Linear>
      ))}
    </Linear>
  );
};

export const WebhookSetupGuide = ({
  connector,
  connectorId,
  connectorWebhookUrlPrefix,
  events,
  tenantId,
  webhookApiVersion,
}) => {
  const [open, setOpen] = useState(false);
  const Arrow = open ? ArrowUpward : ArrowDownward;
  if (!events) return null;
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link color="black" onClick={() => setOpen(!open)} variant="para.md" hoverEffect="none">
        <Linear align="center" orientation="horizontal">
          <Typography>
            View List of&nbsp;
          </Typography>
          {connector}
          &nbsp;Webhook to setup
          &nbsp;
          <Arrow fontSize="medium" />
        </Linear>
      </Link>
      <Collapse
        in={open}
        Component={Card}
        gap="lg"
        variant="shiny"
      >
        <Typography variant="para.sm">
          Make sure the following webhooks are setup in your&nbsp;
          {connector}
          &nbsp;account settings.
        </Typography>
        {events.map(({ event, ...rest }, index) => (
          <WebhookInfo
            connectorWebhookUrlPrefix={connectorWebhookUrlPrefix}
            event={event}
            index={index}
            key={event}
            {...rest}

            connectorId={connectorId}
            tenantId={tenantId}
            webhookApiVersion={webhookApiVersion}
          />
        ))}
      </Collapse>
    </>
  );
};

export const ConnectorGuide = ({
  connector,
  guideLink,
}) => (
  <Linear orientation="vertical" width="100pr">
    <Spacer y="sm" />
    <Card gap={false} variant="shiny" width="100pr">
      <Typography variant="para.xl" size="xl" weight="medium" font="header">
        {`Learn more about ${connector} integration.`}
      </Typography>
      <Spacer y="md" />
      <Typography variant="para.sm">
        {`Learn how to setup your ${connector} connector settings and capabilities. Read our ${connector} connector guide.`}
      </Typography>
      <Spacer y="lg" />
      <Link hoverEffect="none" newTab href={guideLink}>
        <FormButton color="secondary" size="sm">READ DOCS</FormButton>
      </Link>
    </Card>
  </Linear>
);

export const sortBy = (field) => (x, y) => x[field].localeCompare(y[field], undefined, { numeric: true, sensitivity: 'base' });

const nameSort = sortBy('name');

export const MappingTable = (props) => {
  const {
    carriyoEntities,
    disabled,
    getRemoteEntities,
    mappings = {},
    onChange,
    sourceLabel,
    targetLabel,

    connector = {},
    lastSaved = {},

    CarriyoEntityComponent = UnifiedInput,
    CarriyoEntityComponentProps = {},

    reverseColumns,
    variant = 'standard',
  } = props;

  const { connectorId } = connector;
  const {
    // Shopify
    shopifyAccessToken,
    shopifyApiKey,
    shopifyApiSecret,
    shopifyDomainName,

    // WooCommerce
    baseUrl,
    consumerKey,
    consumerSecret,
  } = lastSaved;

  const [loading, setLoading] = useState(false);

  const [remoteEntities] = useAsyncState(async () => {
    const shouldFetch = (
      connectorId
      && (
        (shopifyDomainName && (
          shopifyAccessToken
          || (shopifyApiKey && shopifyApiSecret)
        ))
        || (baseUrl && consumerKey && consumerSecret)
      )
    );
    setLoading(true);
    const { data = [] } = shouldFetch
      ? await getRemoteEntities(connectorId)
      : {};
    setLoading(false);
    return data.filter((entry) => !isEmpty(entry));
  }, [], [
    connectorId,

    // only saved values trigger requests
    // as remote APIs have rate limits
    baseUrl,
    consumerKey,
    consumerSecret,
    shopifyAccessToken,
    shopifyApiKey,
    shopifyApiSecret,
    shopifyDomainName,
  ]);

  const isMinimal = variant === 'minimal';

  const columns = [{
    columnId: kebabCase(sourceLabel),
    label: sourceLabel,
    sortable: false,
    width: 'minmax(auto, 1fr)',
    CellComponent: ({ row: { remoteEntityName } }) => (
      <Tooltip title={remoteEntityName}>
        <Typography variant="para.xs:body" className="u-ellipsis">
          {remoteEntityName}
        </Typography>
      </Tooltip>
    ),
  }, ...(isMinimal ? [{
    align: 'center',
    columnId: 'arrow',
    label: '',
    sortable: false,
    width: 'auto',
    CellComponent: () => <ArrowForward color="primary" size="large" />,
  }] : []), {
    columnId: kebabCase(targetLabel),
    label: targetLabel,
    sortable: false,
    width: 'minmax(auto, 1fr)',
    CellComponent: ({
      row: { carriyoEntityId, remoteEntity },
    }) => (
      <CarriyoEntityComponent
        field="mapping"
        disabled={disabled}
        options={carriyoEntities}
        onChange={(newEntityId) => onChange(remoteEntity, newEntityId, carriyoEntityId)}
        value={carriyoEntityId}
        {...CarriyoEntityComponentProps}
      />
    ),
  }];

  if (reverseColumns) columns.reverse();

  const entries = remoteEntities.sort(nameSort).map(({ name, id }) => ({
    carriyoEntityId: mappings[id],
    remoteEntity: { name, id: `${id}` },
    remoteEntityName: name,
  }));

  return (
    <Linear width="md">
      <Table
        columns={columns}
        header={{ color: isMinimal ? 'transparent' : undefined }}
        loading={loading}
        margin={false}
        rows={entries}
        variant="outlined"
      />
    </Linear>
  );
};

function toMappings(rows) {
  return Object.fromEntries(rows.map((item) => [item.key, item.value]));
}

export const KeyValueMappingTable = ({
  disabled,
  mappings = {},
  onChange,

  keyFieldLabel,
  KeyField = UnifiedInput,
  keyFieldProps = {
    field: 'textKey',
  },
  keyFieldPropsRowTransform = identity,

  valueFieldLabel,
  ValueField = UnifiedInput,
  valueFieldProps = {},
  valueFieldRowTransform = identity,
}) => {
  const [rows, setRows] = useState([]);

  // Expand the mapping first time, and not again
  useEffect(() => {
    const currentMappings = toMappings(rows);
    if (!isEqual(currentMappings, mappings)) {
      setRows(
        Object.entries(mappings).map(([key, value]) => ({
          value,
          key,
        })),
      );
    }
  }, [mappings]);

  const columns = [{
    columnId: kebabCase(keyFieldLabel),
    label: keyFieldLabel,
    sortable: false,
    width: 'minmax(auto, 1fr)',
    CellComponent: ({ row: { key, value }, rowIndex }) => (
      <KeyField
        disabled={disabled}
        value={key}
        onChange={(eventOrValue) => {
          const newKey = typeof eventOrValue === 'string' ? eventOrValue : eventOrValue.target.value;
          const newRows = [].concat(
            rows.slice(0, rowIndex),
            { key: newKey, value },
            rows.slice(rowIndex + 1),
          );
          setRows(newRows);
          onChange(toMappings(newRows));
        }}
        {...keyFieldPropsRowTransform(keyFieldProps, { value, key })}
      />
    ),
  }, {
    columnId: kebabCase(valueFieldLabel),
    label: valueFieldLabel,
    sortable: false,
    width: 'minmax(auto, 1fr)',
    CellComponent: ({ row: { value, key }, rowIndex }) => (
      <ValueField
        disabled={disabled}
        onChange={(newValue) => {
          const newRows = [].concat(
            rows.slice(0, rowIndex),
            { key, value: newValue },
            rows.slice(rowIndex + 1),
          );
          setRows(newRows);
          onChange(toMappings(newRows));
        }}
        value={value}
        {...valueFieldRowTransform(valueFieldProps, { value, key })}
      />
    ),
  }, {
    columnId: 'action',
    label: 'Action',
    width: '70px',
    sortable: false,
    align: 'center',
    CellComponent: ({ row: { key }, rowIndex }) => (
      <Menu
        color="primary"
        options={[{
          Icon: DeleteIcon,
          key,
          label: 'Delete',
          color: 'default',
          onClick: () => {
            const newRows = [].concat(
              rows.slice(0, rowIndex),
              rows.slice(rowIndex + 1),
            );
            setRows(newRows);
            onChange(toMappings(newRows));
          },
          show: true,
        }]}
      />
    ),
  }];

  return (
    <>
      <Linear
        width="lg"
        align="center"
        justify="end"
        orientation="horizontal"
      >
        <TableAddButton
          onClick={() => setRows(
            rows.concat({ key: '', value: '' }),
          )}
        >
          Add Record
        </TableAddButton>
      </Linear>
      <Linear width="lg">
        <Table
          columns={columns}
          margin={false}
          rows={rows}
          rowIdField="key"
          variant="outlined"
        />
      </Linear>
    </>
  );
};

const syncItemsSteps = [{
  stepId: 'summary',
  actions: ({
    onClose,
    startSync,
    syncStyle,
    toggleView,
    update,
    waiting,
  }) => [{
    color: 'secondary',
    onClick: onClose,
    overrideWaiting: true,
    state: waiting ? 'disabled' : 'ready',
    title: 'No',
  }, {
    color: 'primary',
    disabled: !syncStyle,
    onClick: async () => {
      update({ waiting: true });
      const { data: asyncTask = {}, error } = await startSync({ delete: syncStyle === 'fresh' });
      if (error) {
        update({ waiting: false });
      } else {
        update({ asyncTask, visible: false });
        updateAsyncTasks([asyncTask], true);
        toggleView();
      }
    },
    title: 'Yes, Confirm',
  }],
  Content: ({ syncStyle, update, waiting }) => (
    <Linear align="center" height="100pr" gap="md" justify="center" orientation="vertical" width="100pr">
      <Typography variant="para.sm:body">
        This will begin transferring products from Shopify to Carriyo.
        <br />
        Would you like to,
      </Typography>
      <Radio
        disabled={waiting}
        options={[{
          label: 'Overwrite the existing catalogue',
          value: 'fresh',
        }, {
          label: 'Merge to the existing catalogue',
          value: 'merge',
        }]}
        onChange={({ target }) => update({ syncStyle: target.value })}
        value={syncStyle}
      />
      <Typography variant="para.sm:body">
        Do you want to continue?
      </Typography>
    </Linear>
  ),
}];


const getEntityFromAsyncTask = ({ params = [] } = {}) => (
  params.find(({ name }) => name === 'entity')?.value || 'product'
);

const synchronizeDialogHeading = ({ asyncTask }) => {
  const entity = getEntityFromAsyncTask(asyncTask);
  return `Shopify ${startCase(camelCase({ product: 'item catalogue' }[entity] || pluralize(entity)))} Sync`;
};

const SyncItemsDialog = makeProgressDialog({
  steps: syncItemsSteps,
  size: 'md',
  title: synchronizeDialogHeading,
  type: 'wizard',
});

const commonIconProps = { style: { height: 15, width: 15 } };

const stageLabel = (stage) => ({
  prepare: 'Preparing',
  delete: 'Deleting',
  upload: 'Uploading',
}[stage] || stage);

const defaultAsyncTask = {
  progress: {
    current: 0,
    failure: 0,
    success: 0,
    total: 0,
  },
};

const SyncItemsSummary = ({
  asyncTask: {
    error,
    progress = defaultAsyncTask.progress,
    stage,
    status,
    taskLog,
  } = {},
}) => {
  const getProgressText = (count) => {
    if (count === progress.total) return ` - ${count} entries`;
    return ` - ${count}${progress.total ? ` / ${progress.total}` : ''} entries`;
  };
  return status === 'progress' ? ( // eslint-disable-line no-nested-ternary
    <Linear gap="sm" orientation="horizontal">
      <CircularProgress
        size={15}
        value={progress.total ? (progress.current / progress.total) * 100 : undefined}
      />
      <Typography>
        {status === 'progress' ? stageLabel(stage) : startCase(status)}
        {getProgressText(progress.current)}
      </Typography>
    </Linear>
  ) : status === 'error' ? (
    <>
      <Linear gap="sm" orientation="horizontal">
        <WarningIcon color="error" {...commonIconProps} />
        <Typography>{stageLabel(stage)}</Typography>
      </Linear>
      <Typography>
        {error.message}
      </Typography>
    </>
  ) : (
    <Linear gap="sm" orientation="horizontal" width="100pr">
      {status === 'success' && progress.failure === 0 ? (
        <CheckedIcon color="secondary" {...commonIconProps} />
      ) : (
        <WarningIcon color="error" {...commonIconProps} />
      )}
      <Typography>
        {status === 'success' ? 'Finished' : startCase(status)}
        {getProgressText(progress.success)}
      </Typography>
      <Spacer flex />
      {taskLog && (
        <Link href={taskLog}>
          Request Log
        </Link>
      )}
    </Linear>
  );
};

// NOTE: We re-use the item activity async dialog/summary template
// to notify success/error (no other steps) during initial sync of
// locations, orders, etc. when a new shopify app connector is set
export const syncItemsActivityParams = ({
  asyncTask,
  startSync,
}) => ({
  heading: synchronizeDialogHeading,
  Content: SyncItemsDialog,
  Summary: SyncItemsSummary,
  onClose: (_, state) => (state.asyncTask ? dismissAsyncTask(state.asyncTask) : null),
  asyncTask,
  startSync,
  step: 0,
  syncStyle: null,
  visible: true,
  waiting: false,
});

export const shipmentExportActivityParams = ({
  asyncTask,
  startExport,
}) => ({
  heading: 'Shipment Export',
  Content: ({ update, toggleView }) => {
    useAsyncEffect(async () => {
      if (!startExport) return;
      toggleView();
      update({ waiting: true });
      const { data: newAsyncTask = {}, error } = await startExport();
      if (error) {
        update({ waiting: false });
      } else {
        update({ asyncTask: newAsyncTask, visible: false });
        updateAsyncTasks([newAsyncTask], true);
      }
    }, []);
    return null;
  },
  Summary: ({
    asyncTask: {
      error,
      progress = { current: 0, total: 0 },
      stage = 'Preparing',
      status = 'progress',
    } = {},
  }) => {
    const getProgressText = (count) => {
      if (count === progress.total) return ` - ${count} entries`;
      return ` - ${count}${progress.total ? ` / ${progress.total}` : ''} entries`;
    };
    return status === 'progress' ? ( // eslint-disable-line no-nested-ternary
      <Linear gap="sm" orientation="horizontal">
        <CircularProgress
          size={15}
          value={progress.total ? (progress.current / progress.total) * 100 : undefined}
        />
        <Typography>
          {status === 'progress' ? startCase(stage) : startCase(status)}
          {getProgressText(progress.current)}
        </Typography>
      </Linear>
    ) : status === 'error' ? (
      <>
        <Linear gap="sm" orientation="horizontal">
          <WarningIcon color="error" {...commonIconProps} />
          <Typography>{startCase(stage)}</Typography>
        </Linear>
        <Typography>
          {error.message}
        </Typography>
      </>
    ) : (
      <Linear gap="sm" orientation="horizontal" width="100pr">
        {status === 'success' ? (
          <CheckedIcon color="secondary" {...commonIconProps} />
        ) : (
          <WarningIcon color="error" {...commonIconProps} />
        )}
        <Typography>
          {status === 'success' ? 'Finished' : startCase(status)}
          {getProgressText(progress.success)}
        </Typography>
        <Spacer flex />
      </Linear>
    );
  },
  onClose: (_, state) => (state.asyncTask ? dismissAsyncTask(state.asyncTask) : null),
  asyncTask,
  startExport,
  step: 0,
  visible: true,
  waiting: false,
});
