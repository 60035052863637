/* eslint-disable camelcase */
import { get, map, reverse } from 'lodash';
import camelCaseKeys from 'lib-frontend-shared/src/helpers/camelCaseKeys';
import chain from 'lib-frontend-shared/src/helpers/chain';
import snakeCaseKeys from 'lib-frontend-shared/src/helpers/snakeCaseKeys';
import moment from '../helpers/moment';
import {
  carriyoClient,
  nodeMiddlewareClient,
  nodeMiddlewareIOClient,

  // utils
  timeZoneOffset,
  toMerchantsParam,
  getBackendHeadersToAdd,
  userAccessRestrictor,
} from './base';
import { getStates } from '../store';
import { fromShipment } from '../api-transforms';

const generateShipmentQueryFilters = ({
  mock,
  page,
  pageSize,
  maxResults,
  searchString,
  allFilters = {},
  shipmentType,
  sortBy,
  sortDirection,
  aggBy,
  fields,
  allMerchants = false,
  exportCSV = false,
  exportType,
  selectedShipmentIds,
}) => {
  const { global: { selectedMerchantIds } } = getStates();
  return snakeCaseKeys(userAccessRestrictor({
    filters: {
      page,
      pageSize,
      shipmentType,
      ...(maxResults ? { maxResults } : {}),
      ...(fields ? { fields } : {}),
      ...toMerchantsParam(allMerchants ? undefined : (get(allFilters, 'merchant') || selectedMerchantIds)),
      ...(searchString ? { searchString } : {}),
      ...(sortBy ? { sortBy } : {}),
      ...((sortBy && sortDirection) ? { sortDirection: sortDirection.toUpperCase() } : {}),
      ...allFilters,
      ...(aggBy ? { aggBy } : {}),
      ...(mock ? { mock } : {}),
      ...(exportCSV ? { selectedShipmentIds } : {}),
      ...(exportType ? { exportType } : {}),
      tzoffset: timeZoneOffset,
    },
  }));
};

const generateShipmentResponse = (data, {
  transformed = true,
  exportCSV = false,
}) => {
  if (exportCSV) return data;

  const {
    pagination: { page: pageNum, totalCount: totalRows },
    shipments,
    aggBy: aggByResult,
  } = data;

  return {
    aggBy: aggByResult,
    page: Math.max(0, pageNum),
    rawShipments: shipments,
    shipments: transformed ? shipments.map(fromShipment) : shipments,
    totalRows,
  };
};

export const getShipmentList = async ({
  transformed = true,
  exportCSV = false,
  ...restQueryFilters
}, signal) => {
  const url = exportCSV ? '/data-service/shipments/export' : '/shipments';
  const { data } = await nodeMiddlewareClient.get(url, {
    signal,
    params: generateShipmentQueryFilters({
      ...restQueryFilters,
      exportCSV,
    }),
  });
  return generateShipmentResponse(data, { transformed, exportCSV });
};

const directionalShipmentList = (
  shipmentType,
) => (props, ...rest) => getShipmentList({ ...props, shipmentType }, ...rest);

export const getShipments = directionalShipmentList('forward');
export const getReverseShipments = directionalShipmentList('reverse');

export const getShipment = async ({ shipmentId, rawResponse = false }) => {
  const { data: shipment } = await carriyoClient.get(`/shipments/${shipmentId}`);
  if (rawResponse) {
    return {
      shipment: fromShipment(shipment),
      rawShipment: shipment,
    };
  }
  return fromShipment(shipment);
};

export const getRawShipment = async ({ shipmentId }) => {
  const { data: shipment } = await carriyoClient.get(`/shipments/${shipmentId}`);
  return shipment;
};

export const importBulkShipments = async (request) => {
  const { data } = await carriyoClient.post('/shipments/bulk/import', request);
  return data;
};

export const getShipmentStatus = async (partnerReferences = []) => {
  const { data } = await carriyoClient.get('/shipments/bulk/status', {
    params: {
      partner_shipment_reference: partnerReferences,
    },
  });
  return data;
};

export const setPromisedDeliveryDate = async ({
  shipmentId,
  promisedDeliveryDate,
}) => {
  const { data } = await carriyoClient.post(
    `/shipments/${shipmentId}/update-delivery-promise`,
    {
      revised_promised_delivery_date: promisedDeliveryDate,
    },
  );
  return data;
};

export const setDropoffSchedule = async ({
  shipmentId,
  scheduleDropoffFrom,
  scheduleDropoffTo,
}) => {
  const { data } = await carriyoClient.post(
    `/shipments/${shipmentId}/update-delivery-schedule`,
    {
      scheduled_from: scheduleDropoffFrom,
      scheduled_to: scheduleDropoffTo,
    },
  );
  return data;
};

export const setPickupSchedule = async ({
  shipmentId,
  schedulePickupFrom,
  schedulePickupTo,
}) => {
  const { data } = await carriyoClient.post(
    `/shipments/${shipmentId}/update-collection-schedule`,
    {
      scheduled_from: schedulePickupFrom,
      scheduled_to: schedulePickupTo,
    },
  );
  return data;
};

export const convertShipmentsToCsv = async (shipmentData) => {
  const { data } = await nodeMiddlewareIOClient.post('/shipments/upload/csv', shipmentData);
  return data;
};

export const upsertShipment = async ({
  shipmentId,
  payload,
  rawResponse = false,
}) => {
  if (!shipmentId) {
    const { data } = await carriyoClient.post('/shipments', payload);
    return rawResponse ? data : fromShipment(data);
  }
  const { data } = await carriyoClient.post(`/shipments/${shipmentId}/reprocess`, payload);
  return rawResponse ? data : fromShipment(data);
};

export const upsertCustomAttribute = async ({
  shipmentId,
  payload,
  rawResponse = false,
}) => {
  const { data } = await carriyoClient.patch(`/shipments/${shipmentId}/custom-attributes`, payload);
  return rawResponse ? data : fromShipment(data);
};

export const createDraftShipment = async ({ payload }) => {
  const { data } = await carriyoClient.post('/shipments?draft=true', payload);
  return data;
};

export const saveDraftShipment = async ({ shipmentId, payload }) => {
  const { data } = await carriyoClient.patch(`/shipments/${shipmentId}`, payload);
  return data;
};

export const confirmShipment = async ({ shipmentId, payload = {}, rawResponse }) => {
  const { data } = await carriyoClient.post(`/shipments/${shipmentId}/confirm`, payload);
  return rawResponse ? data : fromShipment(data);
};

export const retryShipment = async ({
  carrierAccount,
  preBooked,
  preBookingInfo,
  shipmentId,
  skipPhoneValidation,
  rawResponse = false,
}) => {
  const { data } = await carriyoClient.post(`/shipments/${shipmentId}/reprocess`, {
    ...(preBooked ? snakeCaseKeys({ preBooked, preBookingInfo }) : {}),
    carrier_account: carrierAccount,
    skip_validations: skipPhoneValidation ? ['phone_number_format'] : null,
  });
  return rawResponse ? data : fromShipment(data);
};

export const changeShipmentStatus = async ({
  newStatus,
  shipmentId,
  suppressCommunication,
  updateDate,
  updateReasonCode,
  rawResponse = false,
}) => {
  const { data } = await carriyoClient.post(
    `/shipments/${shipmentId}/update-status`,
    snakeCaseKeys({
      newStatus,
      suppressCommunication,
      updateDate,
      updateReasonCode,
    }),
  );
  return rawResponse ? data : fromShipment(data);
};

export const cancelShipment = async ({
  shipmentId,
  updateReasonCode,
  rawResponse = false,
}) => {
  const { data } = await carriyoClient.post(
    `/shipments/${shipmentId}/cancel`,
    snakeCaseKeys({ updateReasonCode }),
  );
  return rawResponse ? data : fromShipment(data);
};

export const refreshShipmentStatus = async (shipmentId) => {
  const url = `/shipments/bulk/status/refresh${`?shipment_id=${shipmentId}`}`;
  const { data } = await carriyoClient.post(url);
  return data;
};

export const changeShipmentToReadyToShip = async ({
  shipmentId,
  updateDate,
  suppressCommunication,
  schedulePickupTo,
  schedulePickupFrom,
}) => {
  const { data } = await carriyoClient.post(`/shipments/${shipmentId}/ready-to-ship`, {
    ready_to_ship_date: updateDate,
    suppress_communication: suppressCommunication,
    collection: {
      scheduled_from: schedulePickupFrom,
      scheduled_to: schedulePickupTo,
    },
  });
  return fromShipment(data);
};

export const retryLabel = async ({ shipmentId }) => carriyoClient.post(
  `/shipments/${shipmentId}/label/refresh`,
);

const historyTransform = (history) => ({
  data: chain(history)
    .fn(reverse)
    .fn(map, ({
      log_date: logDate,
      updated_by_api: event,
      updated_by_user: author,
      source_id: sourceId,
      source_type: sourceType,
      record,
      shipment,
    }) => {
      const {
        update_date: updateDate,
        ...state
      } = (record ? JSON.parse(record) : shipment) || {};
      return {
        author,
        event,
        sourceId,
        sourceType,
        state,
        timestamp: logDate || updateDate,
      };
    })
    .value,
});

export const getShipmentHistory = async (shipmentId) => {
  const { data = [] } = await carriyoClient.get(`/shipments/${shipmentId}/history`);
  return historyTransform(data);
};


export const estimateShippingCost = async (shipmentId) => {
  const { data = {} } = await carriyoClient.post(`/shipments/${shipmentId}/estimate-shipping-cost`);
  return camelCaseKeys(data);
};

export const getShipmentLogs = async (shipmentId, params) => {
  const { data } = await carriyoClient.get(`shipments/${shipmentId}/logs`, { params });
  return data;
};

export const getShipmentLogsDetails = async (shipmentId, identifier) => {
  const { data = '' } = await carriyoClient.get(`shipments/${shipmentId}/logs/${identifier}`);
  return data;
};

export const getVerifiedCustomerTag = async (shipmentId) => {
  const { data = {} } = await carriyoClient.get(`shipments/${shipmentId}/matching-customer-address`);
  const {
    addresses = [],
  } = data;
  return get(addresses, '[0].verified', false);
};

export const getActivity = async ({ shipmentId }) => {
  const { data } = await nodeMiddlewareClient.get('shipment-activity', {
    headers: {
      ...getBackendHeadersToAdd(),
      'user-tz': moment.tz.guess(),
    },
    params: { shipmentId },
  });
  return data;
};


export const getScheduledShipmentsTrend = async (params) => {
  const { global: { selectedMerchantIds } } = getStates();
  const { data } = await nodeMiddlewareClient.get('/scheduled-deliveries', {
    params: {
      ...toMerchantsParam(selectedMerchantIds),
      ...userAccessRestrictor({ filters: params }),
      tzoffset: timeZoneOffset,
    },
  });
  return data;
};


export const validatePhoneNumber = async (payload) => {
  const { data = {} } = await carriyoClient.post('/checkout/validate/phone', payload);
  return camelCaseKeys(data);
};

export const scanShipments = async ({ merchant, scanId }) => {
  const { data = [] } = await nodeMiddlewareClient.post('/shipments/scan', { scanId }, {
    params: userAccessRestrictor({
      filters: toMerchantsParam(merchant),
      useSnakeCasing: true,
    }),
  });
  return { data: data.map(fromShipment) };
};

export const searchShipments = async ({
  transformed = true,
  ...restQueryFilters
}) => {
  const { data } = await nodeMiddlewareClient.post(
    '/shipments/search',
    generateShipmentQueryFilters(restQueryFilters),
  );
  return generateShipmentResponse(data, { transformed });
};
